.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:focus:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:visited,
.swal-button.swal-button--confirm,
.swal-button.swal-button--confirm:hover,
.swal-button.swal-button--confirm:active,
.swal-button.swal-button--confirm:focus,
.swal-button.swal-button--confirm:focus:active,
.swal-button.swal-button--confirm:not(:disabled):not(.disabled):active,
.swal-button.swal-button--confirm:not(:disabled):not(.disabled):active:focus,
.swal-button.swal-button--confirm:visited {
  background: #042e77;
  border-color: #042e77;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.disabled,
.btn-primary:disabled,
.swal-button.swal-button--confirm:hover,
.swal-button.swal-button--confirm:focus {
  background: #042e77;
  border-color: #042e77;
  opacity: 0.8;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus,
.btn-danger:focus:active,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:visited {
  background: #e6eeff;
  border-color: #221f20;
  color: #221f20;
}

.btn-danger:hover,
.btn-danger:focus {
  opacity: 0.8;
}

.sk-three-bounce .sk-child {
  background-color: #042e77;
}

.header-left input {
  background-color: initial !important;
}

.location-select {
  width: 300px;
}

.bordered-form-box .form-group {
  margin-bottom: 0px;
}

.bordered-form-box .form-title {
  margin-top: 24px;
  margin-bottom: 4px;
}

.form-control-location {
  min-width: 300px;
}

.form-control-location .css-e56m7-control,
.form-control-location .css-pc7y0b-control {
  border-radius: 6px;
  border: 1px solid #f0f1f5;
  color: #6e6e6e;
  height: 56px;
  font-size: 14px;
  padding-left: 6px;
}

.text-primary {
  color: #042e77 !important;
}

[data-headerbg="color_1"] .nav-header .hamburger.is-active .line,
[data-headerbg="color_1"] .nav-header .hamburger .line,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .nav-control
  .hamburger
  .line,
.header-right .header-profile > a.nav-link,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li.mm-active:hover
  > a
  i {
  background: #042e77 !important;
  color: white;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a {
  background-color: #e6eeff;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li.mm-active
  > a
  i,
[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:hover
  i,
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .deznav
  .metismenu
  > li:hover
  > a
  i {
  color: #042e77;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .deznav
  .metismenu
  > li
  > a:before {
  background-color: #042e77;
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
  color: #042e77;
}
.underline {
  text-decoration: underline;
}
.deznav .metismenu .las {
  font-size: 24px;
}

.nav-header .brand-title {
  max-width: 125px;
  height: 110px;
  margin: auto;
}

.nav-header .logo-abbr {
  display: none;
  max-width: 50px;
}

.header-right .header-profile .dropdown-menu a:hover,
.header-right .header-profile .dropdown-menu a:focus,
.header-right .header-profile .dropdown-menu a.active {
  color: #042e77;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e6eeff;
}

.header-right .notification_dropdown .nav-link.warning {
  color: #042e77;
  padding: 10px;
}

.header-right .notification_dropdown .nav-link i {
  font-size: 32px;
}

td.menu-arrow-box {
  width: 60px;
  padding: 0px !important;
}

.menu-arrow {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  color: #7e7e7e;
  cursor: pointer;
}

.menu-arrow:hover {
  background-color: #e6eeff;
  color: #042e77;
}

.menu-arrow:hover a,
a:hover {
  color: #042e77e1;
}

.submenu-hours.menu-arrow {
  border-radius: 12px;
  height: 56px;
  margin-right: -13px;
}

.items-table tbody tr.active,
.items-table tbody tr:hover {
  box-shadow: 0px 4px 4px 0 rgb(0 0 0 / 12%);
}

.switch .switch-group .btn {
  padding: 4px 0px;
}

.switch-btn-sm-width .switch.btn {
  width: 4rem;
}

.td-sm {
  width: 1px;
}

.btn.tp-btn-light.btn-primary,
.btn.tp-btn-light.btn-primary g [fill],
.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: #042e77;
}

.btn.tp-btn-light.btn-primary:hover,
.show > .btn-primary.dropdown-toggle {
  background-color: #042e77;
  color: #ffff;
  border-color: #e6eeff;
}

.btn-primary:not(:disabled):not(.disabled):active g [fill] {
  fill: white;
}

.date-range-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
  /* width: 236px; */
  width: auto;
}

.light.btn-primary {
  color: #042e77;
  background-color: #d8f7ff;
  border-color: #d8f7ff;
}

.light.btn-primary g [fill] {
  fill: #042e77;
}

.light.btn-primary:hover {
  background-color: #042e77;
  border-color: #042e77;
  color: white;
}

.light.btn-primary:hover .light.btn-primary g [fill] {
  color: white;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
  background: #042e77;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: none;
  margin: 0px 2px;
  padding: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button a {
  color: #042e77 !important;
  padding: 8px 16px;
  display: inline-block;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #042e77 !important;
  cursor: pointer;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current a,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover a {
  color: white !important;
}

.footer .copyright a {
  color: #042e77;
}

.card-tabs .nav-tabs {
  background: #e6eeff;
}

.form-title {
  color: #7e7e7e;
  font-weight: 500;
  margin-bottom: 16px;
}

.expand-collapse-title {
  background: #f7f8f8;
}

.form-control:focus {
  border-color: #042e77;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: initial;
  height: 56px;
  width: 126px;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root .MuiInputBase-input {
  text-align: right;
}

.MuiPickersToolbar-toolbar {
  background-color: #042e77 !important;
}

.MuiButton-textPrimary {
  color: #042e77 !important;
}

.MuiInput-underline:after {
  border-bottom-color: #042e77 !important;
}

.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint,
.MuiPickersClock-pin {
  background-color: #042e77 !important;
}

.MuiPickersClockPointer-thumb {
  border-color: #042e77 !important;
}

.pac-container {
  z-index: 9999;
}

.menu-toggle .save-cancel {
  width: calc(100% - 5rem);
}

.save-cancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 21.563rem);
  z-index: 9;
  background: white;
}

[data-sidebar-style="overlay"] .save-cancel {
  width: 100%;
}

.modal.flex-modal {
  display: flex !important;
  visibility: hidden;
  justify-content: center;
  align-items: center;
}

.modal.flex-modal.show {
  visibility: visible;
}

.modal.flex-modal .modal-dialog {
  margin: 0px;
  width: 500px;
}

.modal-content {
  border: none;
  box-shadow: 3px -3px 9px 0px rgb(0 0 0 / 20%);
}

.menu-toggle .loading-overlay {
  width: calc(100% - 5rem);
}

.loading-overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  width: calc(100% - 21.563rem);
  margin: -30px 0 0 -30px;
  height: calc(100vh - 120px);
  background: rgba(255, 255, 255, 0.5);
  z-index: 9;
}

.w-tax-per {
  width: 76px !important;
}

.custom-checkbox {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before,
input[type="checkbox"]:checked:after {
  border-color: #042e77;
  background-color: #042e77;
  cursor: pointer;
}

.custom-control-label {
  cursor: pointer;
}

input[type="checkbox"]:checked:after,
input[type="checkbox"]:after {
  width: 24px;
  height: 24px;
  padding-top: 4px;
}

.report-scrollable {
  max-height: 420px;
  overflow-y: scroll;
}

/* mobile responsive */
.deznav .metismenu > li.mm-active > a,
.deznav .metismenu > li:hover > a,
.deznav .metismenu > li:focus > a {
  color: #042e77;
}

.bottom-menu {
  display: none;
  position: absolute;
  bottom: 12px;
  width: 100%;
}

.image-wrapper {
  width: 160px;
  height: 80px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.user-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: #d8f7ff69;
}

.profile-photo {
  margin-top: 0px !important;
}

.profile-info h4.text-primary {
  color: #042e77 !important;
  margin-bottom: 5px !important;
}

.badge-primary {
  background-color: #d8f7ff;
  color: #042e77;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border-color: #042e77;
  color: #042e77;
}

.list-group-item .la-pen:hover {
  color: #042e77;
}

.list-group-item .group-icon-img {
  margin-right: 18px;
}

.which-input {
  max-width: 100px !important;
}

.date-label {
  min-width: 100px;
  text-align: left;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.status-img {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: -2px;
  right: -4px;
}

.status-img-lg {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -2px;
  right: -4px;
}

.recharge-button {
  background-color: #042e77;
  color: white;
  transition: all 200ms ease-in-out;
  font-size: 12px;
  padding: 5px 10px;
  height: 40px;
}
.recharge-button:hover {
  transform: scale(1.02);
  color: white;
}
.deznav .metismenu > li a > i {
  padding: 0 0.625rem 0 0;
}
.cancel-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 34px;
  width: 34px;
  border-radius: 20px;
  border: 1px solid red;
  color: red;
  font-size: large;
  transition: all 0.3s;
}
.cancel-btn:hover {
  transform: scale(1.1);
}
.media-body p {
  word-break: normal;
}
.underline {
  text-decoration: underline !important;
}

@media only screen and (max-width: 1199px) {
  .nav-header .logo-abbr {
    display: block;
  }
}

@media only screen and (max-width: 768px) {
  .loading-overlay {
    width: 100% !important;
    margin-left: -15px;
  }

  .save-cancel {
    width: 100%;
  }

  .save-cancel .btn {
    width: 35% !important;
  }

  .bottom-menu {
    display: block;
  }

  .notification_dropdown {
    display: none !important;
  }

  .header-right .header-profile > a.nav-link {
    background-color: transparent !important;
  }

  .date-range-dropdown {
    width: 100%;
    margin-bottom: 0px;
  }

  .date-range-dropdown .text-end {
    margin-right: auto !important;
  }

  .date-range-dropdown .dropdown-menu.show {
    width: 100%;
    box-shadow: 3px -3px 9px 0px rgb(0 0 0 / 20%);
  }

  .rdrDateRangePickerWrapper {
    flex-direction: column;
    width: 100%;
  }

  .rdrDefinedRangesWrapper,
  .rdrMonth {
    width: 100%;
  }

  .card-tabs .nav-tabs {
    flex-wrap: wrap;
  }

  .nav-tabs .nav-item {
    width: 33%;
    text-align: center;
  }

  .report-tabs.nav-tabs .nav-item {
    width: 50%;
  }

  .report-cards .rounded-xl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  .report-cards .rounded-xl h2 {
    font-size: 24px !important;
    margin: 0px;
  }

  .location-dropdown span {
    font-size: 14px;
    max-width: 65px;
    margin-top: 2px;
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  .location-select {
    width: 100%;
  }

  .header-left .search_bar {
    display: block;
    position: fixed;
    top: 80px;
    right: -100%;
    width: 100%;
    height: auto;
    transition: 0.1s;
  }

  .header-left .search_bar.mobile-show {
    right: 0px;
    z-index: 999;
  }

  .header-left .search_bar .dropdown-menu,
  .header-left .search_bar .dropdown-menu.show {
    width: 100%;
    left: 0px;
  }

  .dataTables_wrapper .dataTables_paginate a {
    padding: 2px 8px !important;
  }

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    font-size: 18px;
  }

  .h4,
  .h5,
  .h6,
  h4,
  h5,
  h6 {
    font-size: 14px;
  }

  .add-contact-content .form-control {
    width: 100% !important;
    margin-top: 6px;
  }

  .expand-collapse-title {
    padding: 0px 0.5rem !important;
  }

  .menu-arrow {
    height: 44px;
    width: 44px;
  }

  .menu-arrow i {
    font-size: 24px !important;
  }

  .submenu-hours.menu-arrow {
    height: 44px;
  }

  .timepicker-container,
  .MuiFormControl-root.MuiTextField-root {
    height: 40px;
  }

  .form-head {
    display: flex !important;
    align-items: center !important;
  }

  .form-head > * {
    margin-right: inherit !important;
  }

  .form-head .mr-auto {
    margin-right: auto !important;
  }

  .profile-tab .nav-tabs {
    width: 100%;
  }

  .profile-tab .nav-item .nav-link,
  .profile-tab .nav-item .nav-link {
    font-size: 14px;
    margin: 0px;
  }

  .profile .profile-info {
    padding: 0px;
  }

  .profile .profile-photo {
    margin-bottom: 0px;
  }

  .profile .profile-info .profile-details {
    padding-right: 40px;
    text-align: left;
  }

  .profile .profile-info .profile-details .profile-email {
    padding-left: 1rem !important;
    padding-bottom: 0.75rem;
  }

  .profile .profile-info .profile-details .back-btn {
    position: absolute;
    top: 5px;
    right: 20px;
  }
}

.login-form-bx .login-logo .logo-text {
  width: 256px !important;
  padding-left: 0 !important;
  height: 256px;
}

.text-underline {
  text-decoration: underline !important;
}
.popup-warning-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid orange;
  color: orange;
  width: 60px;
  height: 60px;
  line-height: 26px;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
.dropdown-container {
  border: 1px solid #f0f1f5 !important;
  border-radius: 0.75rem !important;
  padding: 0.375rem 0rem !important;
}
.rmsc .item-renderer {
  display: flex !important;
  align-items: baseline !important;
  justify-content: flex-start !important;
  gap: 10px !important;
}
.dropdown-container ul {
  width: 100% !important;
}
.rmsc .item-renderer span {
  margin: 0 !important;
}
.checkbox-label-container {
  display: flex;
  align-items: center;

  gap: 16px; /* Adjust spacing as needed */
}
.checkbox-label-container label {
  margin: 0;
  margin-top: 10px;
}
