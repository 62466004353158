.dropdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-dropdown {
  width: 100%;
  max-width: 400px;
  position: relative;
}

.dropdown-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  height: 56px;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-arrow {
  margin-left: 10px;
  transition: transform 0.3s;
  color: rgb(126, 122, 122);
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  gap: 8px;
  padding: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-list {
  max-height: 200px;
  overflow-y: auto;
  width: 33%;
}

.minute-list {
  width: 33%;
}

.period-list {
  width: 34%;
}

.dropdown-options {
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  display: flex !important;
  justify-content: center !important;
}
.dropdown-options:hover {
  background-color: #094d96 !important;
  color: #fff;
  /* transform: translate(0.3s); */
  transition: all 0.3s;
}

.dropdown-options:hover,
.dropdown-options.selected {
  background-color: #094d96;
  color: white;
}
@media (max-width: 1159px) {
  .dropdown-section {
    height: 41px;
  }
}
@media (max-width: 600px) {
  .custom-dropdown {
    max-width: 100%;
  }

  .dropdown-section {
    font-size: 14px;
    padding: 8px;
  }

  .dropdown-options {
    font-size: 14px;
    padding: 8px;
  }
}
