a {
  text-decoration: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.table,
td,
tr {
  padding: 5px !important;
}

.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}
.css-yk16xz-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 10px !important;
  border: 1px solid #f0f1f5 !important;
  border-radius: 12px !important;
}
.css-1pahdxg-control {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  border-color: #2684ff !important;
  border-radius: 12px !important;
  box-shadow: 0 0 0 0 #2684ff !important;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
.text-font-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.react-tel-input {
  width: auto !important;
}
.react-tel-input .special-label {
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 25px;
  top: -7px;
  background: #fff;
  padding: 0 5px;
  white-space: nowrap;
}
.react-tel-input .form-control {
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 8px !important;
  border: 1px solid #e9e9ebfd !important;
  padding: 18.5px 9px 18.5px 52px !important;
  background-color: white !important;
  /* max-height: 48px; */
}
.react-tel-input .country-list {
  width: 250px !important;
}
.store-modal {
  padding: 20px;
}

.css-13cymwt-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  /* padding: 0.375rem 0.75rem; */
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-13cymwt-control {
  min-height: 42px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1xc3v61-indicatorContainer {
  display: none !important;
}
.css-t3ipsp-control {
  border-color: transparent !important;
  color: #495057;
  border-radius: 0.7rem !important;
  padding: 8px;
  box-shadow: 0 0 1px black !important;
}
.password-input {
  position: relative;
}

.password-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #555; /* Adjust the color as needed */
  cursor: pointer;
}
.forget-password-text {
  margin: 0;
}
.forget-password-text:hover {
  text-decoration: underline;
  cursor: pointer;
}
.dashboard-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
}

.landing-modal {
  background: var(
    --new-linear,
    linear-gradient(180deg, #042e77 -16.87%, #042e77 118.72%)
  );
}
.landing-modal .modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
}
.google-login {
  width: 100%;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important;
  border-radius: 8px !important;
  display: flex;
  justify-content: center;
}
.google-login > div {
  border-radius: 8px !important;
}
.table tr:hover {
  background-color: #d8e6ff !important;
  cursor: pointer;
}
.list-btn {
  border: none;
  transition: calc(0.3s);
}
.list-btn:hover {
  border: none;
  transform: scale(1.1);
}
.loyalty-program {
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  background: var(
    --new-linear,
    linear-gradient(180deg, #1549a3 -16.87%, #376ac2 118.72%)
  );
  color: #ffff;
  min-height: 190px !important;
}

.complaint-image {
  justify-content: space-between;
  padding: 15px;
}
.complaint-image img {
  border-radius: 10px;
}
.add-program {
  display: flex;
  justify-content: end;
  padding: 10px;
}

.loyalty-program-card {
  padding: 12px;
  transition: calc(0.4s);
}
.loyalty-program-card:hover {
  transform: scale(1.01);
}
.loyalty-program-card p {
  color: #ffffffca;
  margin: 0;
}
.program-card {
  display: flex;
  gap: 10px;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
}
.program-card p {
  margin: 5px;
}
.program-card h4 {
  margin: 0;
  color: #ffff;
}
.card-img {
  display: flex;
  height: 70px;
  width: 73px;
  align-items: center;
}
.card-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.page-title {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgb(137, 137, 137);
  padding: 10px;
}
.back-btn {
  background-color: #d8e6ff;
  color: #000;
  transition: calc(0.3s);
}
.back-btn:hover {
  background-color: rgb(211, 211, 211);
  color: #000;
  transform: scale(1.02);
}

.loyaltyItem {
  background-color: #e6eeff;
  border-radius: 10px;
  padding: 14px;
  cursor: pointer;
}

.headerText {
  font-size: 16px;
  color: #000;
  margin: 0;
  padding-top: 5px;
}

.button-group {
  display: flex;
  gap: 10px;
}
.qr-scanner-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan-btn {
  display: none;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #042e77;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px !important;
}

@media (max-width: 768px) {
  .scan-btn {
   display: flex;
  }
}

.scan-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.subheaderText {
  color: darkWhite;
  margin: 0;
  font-size: 12px;
  /* padding-bottom: 12px; */
}

.darkView {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px;
  background-color: #042e77;
  margin: 5px -14px -14px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-details {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.flex-details p {
  text-align: start;
  max-width: 70%;
  word-break: normal;
}

.tillText {
  margin: 0px;
  font-size: 15px;
  color: white;
}

.validText {
  font-size: 15px;
  color: white;
}
.cutleryImage {
  width: 28px;
  height: 28px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radioButtons {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
}

.radioButtons label {
  display: flex;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f0f1f5;
  border-radius: 0.75rem !important;
  align-items: center;
  gap: 10px;
}

.inputBox {
  width: 80%;
  max-width: 300px;
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
}
.offer-action-btn {
  border-radius: 50%;
  height: 33px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #042e77;
  transition: calc(0.3s);
  padding: 0 !important;
}
.offer-action-btn:hover {
  transform: scale(1.2);
}
.offer-acton-options {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.offer-action-btn:after {
  display: none;
}

.pending-row {
  background-color: #ffeae6;
}
.success-row {
  background-color: #e7faec;
}
.danger-row {
  background-color: #fee6ea;
}

.remove-btn {
  position: absolute;
  right: -4px;
  top: -25px;
}
.remove-btn button {
  height: 25px;
  width: 25px;
  border: 1px solid gray;
  border-radius: 50%;
  transition: all 0.3s;
}
.remove-btn button:hover {
  transform: scale(1.2);
  color: red;
  background-color: #ffeae6;
}
.doc-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.doc-form form {
  padding: 2rem;
  background: white;
  border-radius: 10px;
  width: 100%;
}
.tier-name-icon {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
}
.documents-section {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}
.doc-img {
  max-height: 250px;
  max-width: 180px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s;
}
.documents-section p {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  padding-top: 10px;
}
.doc-img:hover {
  transform: scale(1.02);
}
.doc-img img {
  height: 100%;
  width: 100%;
  min-height: 180px;
  object-fit: cover;
  border-radius: 5px;
}
.image-view-modal div {
  height: 700px;
  width: 80vw;
}
.image-view-modal img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.image-view-modal .modal-dialog {
  max-width: none;
}
.cross {
  height: 40px;
  border-radius: 20px;
  width: 40px;
  background-color: #ecf0f3 !important;
  position: absolute;
  right: 10px;
  top: 10px;
}
.cross:hover {
  background-color: #fff !important;
}
.store-card {
  width: 100%;
}
.profile-card {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.profile-card .card {
  height: unset !important;
}

.tier-card {
  width: 30%;
}
.items-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #d8e6ff;
  border-radius: 0.5rem;
  margin-top: 10px;
}
.items-list p {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.quantity-control Button {
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: #eaedf9;
  border-radius: 20px;
  font-weight: 600;
  transition: all 0.3s;
}
.quantity-control Button:hover {
  transform: scale(1.15);
  color: #000;
}
.quantity-control span {
  padding: 4px;
  border: 1px solid gray;
  min-width: 28px;
  text-align: center;
  border-radius: 7px;
}

.language-translate-btn {
  z-index: 2;
  right: 0;
  padding: 1rem;
}
.language-translate-btn button {
  padding: 5px 10px;
  border: none;
  background-color: lightgrey;
  border-radius: 5px;
  font-weight: 500;
}
.add-offer-form p {
  text-align: left;
}
.tier-card p {
  text-align: left;
}
.mini-tabs {
  display: flex;
  justify-content: start;
  padding: 6px;
  grid-gap: 0.7rem;
  gap: 0.7rem;
  /* border: 1px solid lightgray; */
  border-radius: 43px;
  background-color: #e6eeff;
}
.mini-tabs .tab {
  margin: 0;
  background-color: transparent !important;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}
.mini-tabs .active-tab {
  margin: 0;
  background-color: #fff;
  color: #042e77;
  padding: 5px 10px 5px 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 20px;
}
.hover-scale {
  transition: all 0.3s;
}
.hover-scale:hover {
  transform: scale(1.05);
}
.edit-icon {
  font-size: large;
  transition: all 0.3s;
}
.edit-icon:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.terms-section p {
  text-align: start;
}
.request-section-card {
  background-color: #d8e6ff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  gap: 5px;
}
.request-section-card p {
  margin: 0;
  color: rgb(96, 93, 93);
  text-align: left;
  word-break: normal;
}
.request-section-card span {
  color: #042e77;
  font-weight: 550;
}
.time-option-list {
  display: none;
  position: absolute;
  z-index: 10;
  background: white;
  border: 1px solid #f30808;
}
.time-option-list .show {
  background-color: #042e77;
}
.store-details-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
@media (max-width: 600px) {
  .inputBox {
    width: 90%;
  }
  .profile-card {
    flex-direction: column;
    width: 100%;
  }
  .store-card {
    flex-direction: column;
  }
  .flex-details {
    flex-direction: column;
  }
  .flex-details p {
    max-width: 100% !important;
  }
  .tier-card {
    width: 100%;
  }
  .image-view-modal div {
    height: 100%;
    width: unset;
  }
  .image-view-modal img {
    object-fit: contain;
  }
  .mini-tabs {
    /* padding: 0; */
    gap: 0 !important;
  }
  .mini-tabs .tab {
    font-size: 12px;
    padding: 6px 0px 6px 0px;
  }
  .mini-tabs .active-tab {
    font-size: 12px;
    padding: 6px 0px 6px 0px;
  }
  .request-section-card p {
    font-size: 12px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 601px;
  }
}

@media screen and (max-width: 780px) {
  .store-cards {
    flex-wrap: wrap;
  }
  .loyalty-program {
    width: 100% !important;
  }
}
@media screen and (max-width: 400px) {
  .complaint-image img {
    width: 90px !important;
    height: 90px !important;
  }
  .add-program {
    justify-content: center;
    width: 100% !important;
  }
  .add-program button {
    width: 100% !important;
  }
  .back-btn {
    display: none;
  }
  .store-details-actions button {
    font-size: 0.625rem !important;
    padding: 0.6rem;
  }
}
.disabled-row {
  background-color: #e9ecef !important; /* Light gray background */
  color: #7d6f6c !important; /* Gray text */
  pointer-events: none !important; /* Disable clicking */
  opacity: 0.65 !important; /* Slightly fade the row */
}
.table thead th {
  text-transform: none !important;
}
