/* General */
.page-titles h4 {
  /* font-weight: bold; */
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  text-align: start;
}
.page-titles {
  display: flex !important;
  justify-content: flex-start !important;
}

.card {
  margin: 10px 0;
}
.contest-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.timing-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* padding-left: 0.8rem;
  padding-right: 0.8rem; */
}
.contest-profile p {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.data-card {
  display: flex;
  align-items: center;
  background: #e0e6fb;
  border-radius: 8px;
  padding: 16px;
  align-items: start;
  margin-bottom: 16px;
  flex: 1 1 calc(33% - 20px);
  min-width: 200px;
  transition: background 0.3s;
  margin: 10px;
}

.data-card .icon {
  background: #fafafb;
  border-radius: 20%;
  width: 42px;
  height: 42px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #111781c5;
}
.icon i {
  font: unset;
  font-size: 20px;
}
.data-card .title {
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
}

.data-card .percentage {
  /* font-size: 20px; */
  font-weight: 600;

  color: #111781c5;
}
.data-card .details {
  display: flex;
  flex-direction: column;
}

.winner-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Responsive */
@media (max-width: 768px) {
  .data-card {
    flex: 1 1 calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .data-card {
    flex: 1 1 100%;
    margin: 0;
    margin-top: 10px;
  }
}
