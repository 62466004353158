.coupons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.search-container {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  gap: 10px;
  width: 80%;
  background-color: #fff;
}

.search-container .input-section {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.input-section .react-tel-input .form-control {
  max-height: 48px !important;
}

.react-tel-input .form-control {
  background-color: #f4f1f1;
}

.search-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px 0px 0px 8px;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
}

.search-container button {
  padding: 10px 20px;
  background-color: #042e77;
  color: white;
  border: none;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
  max-width: fit-content;
  width: 100%;
}

.search-container button:hover {
  background-color: #0d3d90;
}

.listing-section {
  margin-top: 10px;
  width: 80%;
  padding: 20px;

  background-color: #fff;
}
/* .filter-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
} */

.filter-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #e6eeff;
  padding: 5px;
  border-radius: 8px;
  flex-wrap: nowrap;
}
.tab {
  margin: 0;
  color: #042e77;
  font-weight: 500;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  min-width: 90px;
  text-align: center;
  cursor: pointer;
}
.active-tab {
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #000;
  margin: 0;
  min-width: 90px;
  text-align: center;
  transition: color 0.3s ease-in-out, background-color 0.5s ease-in-out,
    border-color 0.5s ease-in-out;
}
.filter-tabs p {
  min-width: 50%;
}

@media (max-width: 600px) {
  .search-container {
    flex-direction: column;
    width: 100%;
  }

  .search-container input {
    border-radius: 8px;
  }
  .listing-section {
    width: 100%;
  }
  .search-container button {
    max-width: unset;

    width: 100%;
    border-radius: 4px;
  }
  .filter-section {
    flex-direction: column;
  }
  .filter-tabs {
    width: 100%;
  }
  .filter-tabs p {
    min-width: 50%;
  }
}
