.skeleton-card {
  background-color: #f0f4ff;
  height: 115px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 26px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 100%;
}

.skeleton-circle-left,
.skeleton-circle-right,
.skeleton-logo,
.skeleton-dashed-line,
.skeleton-title,
.skeleton-sub-title,
.skeleton-valid-date {
  background-color: #e0e0e0; /* Grey background for skeleton loader */
  animation: shimmer 1.5s infinite linear;
}

.skeleton-circle-left,
.skeleton-circle-right {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.skeleton-circle-left {
  left: -16px;
}

.skeleton-circle-right {
  right: -16px;
}

.skeleton-logo {
  width: 67px;
  height: 66px;
  border-radius: 50%;
  z-index: 2;
}

.skeleton-dashed-line {
  width: 1px;
  height: 66px;
  background-color: #e0e0e0;
  margin: 0 15px;
  z-index: 2;
}

.skeleton-description {
  flex: 1;
  padding-left: 15px;
}

.skeleton-title,
.skeleton-sub-title,
.skeleton-valid-date {
  height: 16px;
  margin-bottom: 9px;
  border-radius: 4px;
}

.skeleton-title {
  width: 60%;
}

.skeleton-sub-title {
  width: 50%;
}

.skeleton-valid-date {
  width: 40%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@media (max-width: 768px) {
  .skeleton-card {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .skeleton-circle-left,
  .skeleton-circle-right {
    display: none;
  }

  .skeleton-dashed-line {
    display: none;
  }

  .skeleton-description {
    padding-left: 0;
    text-align: center;
  }
}
