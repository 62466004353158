/* src/jsx/components/QrCodeSection/QrCodeScanner.css */

.qr-scanner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  max-width: 600px;
  margin: 0 auto;
}

.camera-icon {
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;
  transition: transform 0.2s;
}

.camera-icon:hover {
  transform: scale(1.1);
}
.camera-icon svg {
  color: #042e77;
}

.qr-scanner {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.qr-scanner svg {
  border: 30px solid rgba(100, 100, 1, 0.1) !important;
}

p {
  font-size: 1rem;
  color: #333;
  text-align: center;
  word-break: break-all;
}

@media (max-width: 768px) {
  .qr-scanner-container {
    padding: 10px;
  }

  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .qr-scanner {
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.25rem;
  }

  p {
    font-size: 0.8rem;
  }

  .qr-scanner {
    max-width: 250px;
  }
}
