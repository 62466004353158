.coupon-card {
  background-color: #f0f4ff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 26px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.circle-left,
.circle-right {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.circle-left {
  left: -16px;
}

.circle-right {
  right: -16px;
}

.store-logo {
  width: 67px;
  height: 66px;
  border-radius: 50%;
}

.dashed-line {
  margin: 0 15px;
}

.description {
  flex: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  margin: 0;
}

.sub-title {
  font-size: 14px;
  color: #000;
  margin: 0;
}

.valid-date {
  font-size: 14px;
  margin: 0;
  color: rgb(158, 154, 154);
}

@media (max-width: 768px) {
  .coupon-card {
    margin: 0;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin: 0;
  }
  .sub-title {
    font-size: 12.5px;
    text-align: left;
    color: #000;
    margin: 0;
  }
  .valid-date {
    font-size: 11px;
    margin: 0;
    color: rgb(158, 154, 154);
  }

  .description {
    padding-left: 0;
    text-align: center;
  }
}
@media (max-width: 500px) {
  .title {
    font-size: 15px;
  }
  .sub-title {
    font-size: 11px;
  }
  .valid-date {
    font-size: 10px;
  }
  .description {
    padding-right: 5px;
  }
}
